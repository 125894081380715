import React, { Component } from 'react';

import breadcrumbs from './breadcrumbs';
import Link from '../components/Link';

class Partnerhub extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { site } = this.props;

    return (
      <div className="partnerhub">
        <Link className="exit-partnerhub" site={site} to={site}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="110.395px"
            height="122.88px"
            viewBox="0 0 110.395 122.88"
            xmlSpace="preserve"
          >
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.359,17.16L75.68,9.377L75.99,0h34.404v61.439v61.44H75.99l-0.311-6.835 l17.68-10.946V17.16L93.359,17.16z M82.029,79.239v-34.54H47.004V13.631L0,61.937l47.004,48.373v-31.07H82.029L82.029,79.239z"
              ></path>
            </g>
          </svg>
          Exit Partner Hub
        </Link>

        <iframe
          frameborder="0"
          src="https://app.pickit.com/#/brand/62bc252d72bf24d4a9bca716"
        />
      </div>
    );
  }
}

export default Partnerhub;
